<template>
  <div class="music-player">
	<div class="background">
		<div class="section" style="display: flex; justify-content: space-between;">
			<span style="font-size: 17px; display: block; font-weight: 500;">NOW PLAYING</span>
			<img src="../icons/music-filter.svg" style="width: 6%;" alt="" />
		</div>
		<div class="section">
			<!-- <img src="../assets/cover.png" style="width: 100%;" alt="" /> -->
			<div class="carousel"
			@touchstart="handleTouchStart"
			@touchmove="handleTouchMove"
			@touchend="handleTouchEnd">
			  <div class="slides" :style="{ transform: 'translateX(' + (-currentIndex * 100) + '%)' }">
				<img v-for="(image, index) in images" :src="image" :key="index" class="slide">
			  </div>
			</div>
		</div>
		<div class="section">
			<span style="font-size: 25px; font-weight: bold;">{{songname}}</span>
			<br />
			<span style="line-height: 35px;">{{singer}}</span>
		</div>
		<div class="section" style="display: flex; justify-content: space-between; align-items: center;">
			<!-- 进度条 -->
			<div class="progress-bar"	
			@click="seek($event)"
			style="width: 85%;"
			>
				<div class="progress" :style="{ width: `${progress}%` }"></div>
			</div>
			<!-- 显示剩余时间 -->
			<div class="time-remaining">{{ timeRemaining }}</div>
		</div>
		<div class="section controls">
			<!-- 播放、暂停按钮 -->
			<img src="../icons/repeate-music.svg" style="width: 12%;" alt="" />
			<img src="../icons/next.svg" style="width: 12%;" alt="" />
			<img :src="music_icons" style="width: 14%;" alt="" @click="play" />
			<img src="../icons/previous.svg" style="width: 12%;" alt="" />
			<img src="../icons/volume-high.svg" style="width: 12%;" alt="" />
		</div>
	  </div>
  </div>
</template>

<script>

import axios from 'axios'
	
export default {
	
	components: {
		
	},
	
  data() {
	return {
		songname: '情侣/闺蜜/好友纪念相框',
		singer: '定制请前往拼多多搜索我们店铺绘梦良品',
		images: ['cover1.jpg', 'cover2.jpg', 'cover3.jpg', 'cover4.jpg'],
        musicUrl: '/music.mp3',
		currentIndex: 0,
		music_status: false,
		progress: 0, // 初始进度为 0
		audioPlayer: null ,// 音乐播放器对象,
		timeRemaining: '00:00', // 初始剩余时间为 00:00
	};
  },
	mounted() {
        
        this.startCarousel();
		
		const orderid = this.$route.query.orderid
        
        if(orderid){
            axios.get('https://source.fntsycw.com/getOrderInfo?orderid='+orderid).then(result=>{
                
                //handle music url
                
                this.songname = result.data[0]?.songname || this.songname
                this.singer = result.data[0]?.singer || this.singer
                this.musicUrl = result.data[0]?.musicUrl || this.musicUrl
                
                // 创建音乐播放器对象
                this.audioPlayer = new Audio(this.musicUrl);
                
                // 监听音乐播放器的时间更新事件
                this.audioPlayer.addEventListener('timeupdate', this.updateProgress);
                
            	if(result.data[0]?.imgLinks?.length>0){
            		this.images = result.data[0].imgLinks
            	}
                
                return
                
            }).catch(error=>{
                
                console.log(error)
                
                // 创建音乐播放器对象
                this.audioPlayer = new Audio(this.musicUrl);
                
                // 监听音乐播放器的时间更新事件
                this.audioPlayer.addEventListener('timeupdate', this.updateProgress);
                
                return
                
            })
        }
		
        
        //创建音乐播放器对象
        this.audioPlayer = new Audio(this.musicUrl);
        
        //监听音乐播放器的时间更新事件
        this.audioPlayer.addEventListener('timeupdate', this.updateProgress);
        


	},
	unmounted() {
		this.stopCarousel();
	},
	
	computed:{
	  
		music_icons() {
			// 计算折扣后的价格
			if(this.music_status){
				return '/pause.svg'
			}else{
				return '/play.svg'
			}
		}
	},
  methods: {
	  
	//touch
	
	handleTouchStart(event) {
	  this.startX = event.touches[0].clientX;
	},
	handleTouchMove(event) {
	  this.endX = event.touches[0].clientX;
	},
	handleTouchEnd() {
	  const deltaX = this.endX - this.startX;
	  if (deltaX > 50) { // 右滑动，切换到上一张图片
		this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
	  } else if (deltaX < -50) { // 左滑动，切换到下一张图片
		this.currentIndex = (this.currentIndex + 1) % this.images.length;
	  }
	},
	  
	startCarousel() {
		this.interval = setInterval(this.nextSlide, 3000); // 自动播放间隔为3秒
	},
	stopCarousel() {
		clearInterval(this.interval);
	},
	nextSlide() {
		this.currentIndex = (this.currentIndex + 1) % this.images.length;
	},
	// 播放音乐
	play() {
		this.music_status ? this.audioPlayer.pause() : this.audioPlayer.play()
		this.music_status = !this.music_status
	},
	
	//剩余时间
	
	updateRemainingTime() {
		const currentTime = this.audioPlayer.currentTime;
		const totalTime = this.audioPlayer.duration;
		if (!isNaN(totalTime)) {
			const remainingSeconds = Math.round(totalTime - currentTime);
			const minutes = Math.floor(remainingSeconds / 60);
			const seconds = remainingSeconds % 60;
			this.timeRemaining = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		}
	},
	
	
	// 更新进度条
	updateProgress() {
		const currentTime = this.audioPlayer.currentTime;
		const totalTime = this.audioPlayer.duration;
		if (!isNaN(totalTime)) {
			this.progress = (currentTime / totalTime) * 100;
			this.updateRemainingTime(); // 更新剩余时间
		}
	},
	// 点击进度条调整播放位置
	seek(event) {
		const progressBar = event.target;
		const rect = progressBar.getBoundingClientRect();
		const clickX = event.clientX - rect.left;
		const percent = clickX / progressBar.clientWidth;
		const duration = this.audioPlayer.duration;
		this.audioPlayer.currentTime = duration * percent;
	},
	
  }
};
	
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
	.carousel {
	  position: relative;
	  width: 100%;
	  overflow: hidden;
	}
	.slides {
	  display: flex;
	  transition: transform 0.5s ease;
	}
	.slide {
	  width: 100%;
	}
	
	
	.background {
		height: 100vh;
		padding: 6%;
		color: white;
		background-color: black;
	}
	
	.section {
		margin-bottom: 18px;
	}
	
	.progress-bar {
		border-radius: 10px;
		height: 10px;
		background-color: #484848; /* 进度条背景颜色 */
		cursor: pointer; /* 鼠标样式 */
	}
	
	.progress {
		border-radius: 10px;
		height: 100%;
		background-color: #ffffff; /* 进度条颜色 */
	}
	
	.controls {
		display: flex;
		justify-content: space-around;
	}
	
</style>
